<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <loading-notification :show="loading || verifying" />
        <error-notification :show="assignedError">
          <span>{{ $t('notifications.auth.country_assigned') }}</span>
        </error-notification>
        <error-notification :show="assignedIntegralError">
          <span>{{ $t('notifications.auth.country_integral_assigned') }}</span>
        </error-notification>
      </div>
      <div class="row">
        <div class="flex xs12">
          <va-select
            v-model="selectedCountry"
            :label="$t('countries.inputs.countrySelect')"
            :options="countriesList"
            :loading="isLoading.countries"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            @update-search="asyncFindCountriesData"
            key-by="name"
            text-by="name"
            searchable
          />
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md4">
          <va-input
            color="info"
            v-model="form.name.value"
            :disabled="loading"
            :label="$t('layout.form.nameInput')"
            :error="!!form.name.errors.length"
            :error-messages="translatedErrors(form.name.errors)"
            @blur="validate('name')"
          >
            <va-popover
              :message="$t('layout.translation')"
              placement="top"
              slot="append"
            >
              <va-button
                flat
                small
                type="button"
                color="primary"
                style="margin-right: 0;"
                icon="entypo entypo-language"
                @click="openTranslator"
              />
            </va-popover>
          </va-input>
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            :value="$t(form.name.value)"
            :disabled="true"
            :label="$t('layout.form.name_translated_input')"
          />
        </div>
        <div class="flex xs12 md2">
          <va-input
            color="info"
            v-model="form.alpha_code.value"
            :disabled="loading"
            :label="$t('countries.inputs.alphaCodeInput')"
            :error="!!form.alpha_code.errors.length"
            :error-messages="translatedErrors(form.alpha_code.errors)"
            @blur="validate('alpha_code')"
          />
        </div>
        <div class="flex xs12 md2">
          <va-input
            color="info"
            v-model="form.calling_code.value"
            :disabled="loading"
            :label="$t('countries.inputs.callingCodeInput')"
            :error="!!form.calling_code.errors.length"
            :error-messages="translatedErrors(form.calling_code.errors)"
            @blur="validate('calling_code')"
          />
        </div>
      </div>
      <div class="row">
        <div class="flex xs12">
          <va-select
            v-model="selectedRegion"
            :label="$t('countries.inputs.regionInput')"
            :options="regionsList"
            :loading="isLoading.regions"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="i18nName"
            searchable
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-select
            v-model="selectedManager"
            :label="$t('countries.inputs.managerInput')"
            :options="managersList"
            :loading="isLoading.managers"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="managerEmail"
            :label="$t('countries.inputs.emailManagerInput')"
            disabled
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-select
            v-model="selectedManagerIntegral"
            :label="$t('countries.inputs.managerIntegralInput')"
            :options="managersIntegralList"
            :loading="isLoading.managersIntegral"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="managerIntegralEmail"
            :label="$t('countries.inputs.emailManagerIntegralInput')"
            disabled
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading || verifying"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >{{ $t('layout.form.save') }}</text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal
        :edit="true"
        :path="form.name.value"
        translation-type="both"
        @saved="closeTranslatorModal"
      />
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'countries-form',
  components: {
    TranslatorModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    country: {
      type: Object,
      required: false,
      default: function () {
        return {
          CountryId: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.country.id === 0
    },
    managerEmail () {
      return this.selectedManager ? this.selectedManager.email : null
    },
    managerIntegralEmail () {
      return this.selectedManagerIntegral ? this.selectedManagerIntegral.email : null
    },
  },
  data () {
    return {
      currentSearchTerm: '',
      translatorModal: false,
      verifying: false,
      assignedError: false,
      assignedIntegralError: false,
      isLoading: {
        regions: false,
        countries: false,
        managers: false,
        managersIntegral: false,
      },
      regionsList: [],
      selectedRegion: '',
      countriesList: [],
      selectedCountry: '',
      managersList: [],
      selectedManager: '',
      managersIntegralList: [],
      selectedManagerIntegral: '',
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        name_pt: {
          value: ' ',
          validate: {
            required: true,
          },
          errors: [],
        },
        name_en: {
          value: ' ',
          validate: {
            required: true,
          },
          errors: [],
        },
        alpha_code: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        calling_code: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    country (val) {
      this.setCountry(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
    selectedCountry (val) {
      this.checkCountry(val)
    },
    selectedManager (val) {
      this.checkManager(val)
    },
    selectedManagerIntegral (val) {
      this.checkManagerIntegral(val)
    },
  },
  created () {
    if (this.country) {
      this.setCountry(this.country)
    }

    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setCountry (val) {
      this.setFormData(val)
      if (val.region) {
        val.region.i18nName = this.$t(val.region.name)
        this.selectedRegion = val.region
      }
      if (val.manager) {
        this.selectedManager = val.manager
      }
      if (val.manager_integral) {
        this.selectedManagerIntegral = val.manager_integral
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    initialData () {
      this.asyncFindRegion('')
      this.asyncFindManagers('3')
      this.asyncFindCountriesData()
      this.asyncFindManagersIntegral('8')
    },
    async asyncFindCountriesData (name) {
      if (!name) return

      this.isLoading.countries = true
      this.currentSearchTerm = name
      let response = null
      try {
        response = await fetch(`https://restcountries.com/v2/name/${name}?fields=name,alpha2Code,translations,callingCodes`, {
          method: 'get',
        })
        response = await response.json()
      } catch (e) {
        this.isLoading.countries = false
        return
      }

      if (name === this.currentSearchTerm) {
        this.countriesList = response || []
        this.isLoading.countries = false
      }
    },
    async asyncFindRegion (query) {
      if (this.currentUser.can('Regions', 'index')) {
        await this.asyncFind(query, 'regions', 'regions')
        for (const c of this.regionsList) {
          c.i18nName = this.$t(c.name)
        }
      } else {
        if (this.currentUser.region) {
          this.currentUser.region.i18nName = this.$t(this.currentUser.region.name)
          this.regionsList = [
            this.currentUser.region,
          ]
          this.selectedRegion = this.currentUser.region
        }
      }
    },
    asyncFindManagers (query) {
      this.asyncUsers(query, 'managers', 'users')
    },
    asyncFindManagersIntegral (query) {
      this.asyncUsers(query, 'managersIntegral', 'users')
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?group=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    checkCountry (country) {
      if (country.translations.es) {
        this.setFormValue('name', country.translations.es)
      }
      if (country.translations.br) {
        this.setFormValue('name_pt', country.translations.br)
      } else if (country.translations.pt) {
        this.setFormValue('name_pt', country.translations.pt)
      }
      if (country.name) {
        this.setFormValue('name_en', country.name)
      }
      if (country.alpha2Code) {
        this.setFormValue('alpha_code', country.alpha2Code)
      }
      if (country.callingCodes.length > 0) {
        this.setFormValue('calling_code', country.callingCodes[0])
      }
      this.validateAll()
    },
    async checkManager (user) {
      if (this.country && this.country.manager) {
        if (user.id === this.country.manager.id) return
      }
      this.verifying = true

      let response = false
      try {
        response = await this.$http.get(`users/ownership/${user.id}?countries`)
      } catch (e) {
        this.verifying = false
        return
      }

      this.assignedError = response.data.data
      this.verifying = false
    },
    async checkManagerIntegral (user) {
      if (this.country && this.country.manager_integral) {
        if (user.id === this.country.manager_integral.id) return
      }
      this.verifying = true

      let response = false
      try {
        response = await this.$http.get(`users/ownership/${user.id}?integrals`)
      } catch (e) {
        this.verifying = false
        return
      }

      this.assignedIntegralError = response.data.data
      this.verifying = false
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const country = this.getFormData(this.country)

      if (this.selectedRegion) {
        country.region_id = this.selectedRegion.id
      }
      delete country.region

      if (this.selectedManager) {
        country.manager_id = this.selectedManager.id
      }
      delete country.manager

      if (this.selectedManagerIntegral) {
        country.manager_integral_id = this.selectedManagerIntegral.id
      }
      delete country.manager_integral

      if (country.districts) {
        delete country.districts
      }

      this.assignedError = false
      this.$emit('submit', country)
    },
  },
}
</script>
